import superagent from 'superagent';
import { DEFAULT_TIMEOUT } from 'services/consts';

const responseCallback = (resolve, reject) => (err, res) => {
  if (err) {
    if (res.body) {
      // err only contains a string with a native Javascript Error object
      // which is sometimes handled differently in browsers and causes problems
      // reject with an object containing the full body of the response
      // this response is a JSON object and therefore requires no further parsing
      reject({
        response: {
          body: res.body,
        },
      });
    } else {
      // unhandled in most cases
      reject(err.message || err);
    }
  } else if (!res.ok) {
    reject(res.body);
  } else if (res.status === 201 && res.header.location) {
    resolve(res.header.location);
  } else {
    resolve({ res: res.body, url: res.req.url, head: res.header });
  }
};

export default class RestClient {
  constructor(endpoint, timeout) {
    this.endpoint = endpoint;
    this.timeout = { response: (timeout || DEFAULT_TIMEOUT) * 1000 };
  }

  find(path, queryParams) {
    const objectToSet = { Accept: 'application/json' };
    return new Promise((resolve, reject) => {
      superagent
        .get(this.endpoint + path)
        .timeout(this.timeout)
        .query(queryParams)
        .set(objectToSet)
        .end(responseCallback(resolve, reject));
    });
  }

  create(path, entity, authorization, lang) {
    const header = {
      'Content-Type': 'application/json',
    };
    if (lang) {
      header['Accept-Language'] = lang;
    }
    if (authorization) {
      // eslint-disable-next-line
      header['Authorization'] = authorization;
    }
    // use superagent.agent() to be able to handle cookies
    return new Promise((resolve, reject) => {
      superagent
        .agent()
        .post(this.endpoint + path)
        .type('json')
        .timeout(this.timeout)
        .set(header)
        .send(entity)
        .withCredentials()
        .end(responseCallback(resolve, reject));
    });
  }

  get(path) {
    return new Promise((resolve, reject) => {
      superagent
        .get(this.endpoint + path)
        .timeout(this.timeout)
        .set('Accept', 'application/json')
        .end(responseCallback(resolve, reject));
    });
  }

  put(path, entity) {
    return new Promise((resolve, reject) => {
      superagent
        .put(this.endpoint + path)
        .timeout(this.timeout)
        .set('Content-Type', 'application/json')
        .send(entity)
        .end(responseCallback(resolve, reject));
    });
  }
}
