/**
 * The connected component of ConnexionInterne.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  clearError, fetchTools,
} from 'pages/step/PaymentCheck/dispatchTools';
import hoc from 'pages/step/PaymentCheck/PaymentCheckHOC';
import init from 'services/init';

function mapStateToProps(state) {
  return {
    error: init(() => state.agent.error, null),
    isLoading: init(() => state.steps.isLoading, false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError: clearError(dispatch),
    fetch: fetchTools(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc()));
