/**
 * The connected component of ConnexionInterne.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ConnexionInterne from 'pages/step/ConnexionInterne/ConnexionInterne';
import hoc from 'pages/step/ConnexionInterne/ConnexionInterneHOC';
import {
  setCodeAgentTools, setPasswordTools, clearError, fetchTools,
} from 'pages/step/ConnexionInterne/dispatchTools';
import init from 'services/init';

function mapStateToProps(state, props) {
  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
    error: init(() => state.agent.error, null),
    step: init(() => state.step.step, 0),
    isLoading: init(() => state.steps.isLoading, false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError: clearError(dispatch),
    fetch: fetchTools(dispatch),
    setCodeAgent: setCodeAgentTools(dispatch),
    setPassword: setPasswordTools(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc(ConnexionInterne)));
