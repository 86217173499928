/**
 * Action creator generator used for payment check.
 */
import ws from 'services/ws';
import {
  FETCH_CONNEXION, RECEIVE_CONNEXION, RECEIVE_CONNEXION_FAILURE,
} from './ActionTypes';

export const requestPaymentCheck = (
  name,
  numero,
) => ({
  type: FETCH_CONNEXION,
  name,
  numero,
});

export const receivePaymentCheck = (
  name,
  numero,
  res,
) => ({
  type: RECEIVE_CONNEXION,
  name,
  numero,
  res,
});

export const receiveFailurePaymentCheck = (error) => ({
  type: RECEIVE_CONNEXION_FAILURE,
  error,
});

function callRequestPaymentCheck(
  authorization,
  dispatch,
  endpoint,
  timeout,
  alreadyCalled,
) {
  const lang = sessionStorage.getItem('lang');
  ws(endpoint, timeout).paymentCheck(
    authorization,
    lang,
  ).then(({ res }) => {
    sessionStorage.setItem('resume', JSON.stringify(res));
    window.location.replace('/resume');
  }, (error) => {
    if (!alreadyCalled
      && sessionStorage.getItem('token.agent')
    ) {
      const errMsg = error.response.body;
      // check if agent is logged in to prevent renewing
      // client token
      if (errMsg.error.code === 401 && errMsg.error.label === 'UNAUTHORIZED') {
        ws(endpoint, timeout)
          .refreshAgentToken('token.client')
          .then(({ head }) => {
            // used for parameter of callRequestPaymentCheck
            // during second call
            const renewedAuth = head.authorization;
            sessionStorage.setItem('token.client', renewedAuth);
            callRequestPaymentCheck(
              renewedAuth,
              dispatch,
              endpoint,
              timeout,
              true,
            );
          }, (refreshTokenExpiredError) => {
            // refresh token expired, redirect to homepage
            const refreshError = refreshTokenExpiredError.response.body;
            sessionStorage.removeItem('token.agent');
            sessionStorage.setItem('token.agent.expired', refreshError.message);
            window.location.replace('/');
          });
      } else {
        // token expired error
        const parseError = error.response.body;
        dispatch(receiveFailurePaymentCheck(error));
        // store entire error for usage later
        sessionStorage.setItem('errorPayment', JSON.stringify(parseError));
        window.location.replace('/');
      }
    } else {
      // check if client token is expired here
      const parseError = error.response.body;
      if (parseError
        && parseError.error.code === 401
        && parseError.error.label === 'UNAUTHORIZED'
      ) {
        // client token expired
        sessionStorage.setItem('token.client.expired', 'Session expirée');
        window.location.replace('/');
      } else {
        // token not expired, different error
        dispatch(receiveFailurePaymentCheck(error));
        if (parseError.state === 'REFUSED') {
          sessionStorage.setItem('resume', JSON.stringify(parseError));
          window.location.replace('/resume');
        } else {
          sessionStorage.setItem('errorPayment', JSON.stringify(parseError));
          window.location.replace('/');
        }
      }
    }
  });
}

export const fetchPaymentCheck = (
  authorization,
  dispatch,
  endpoint,
  timeout,
) => {
  requestPaymentCheck(
    authorization,
  );
  callRequestPaymentCheck(
    authorization,
    dispatch,
    endpoint,
    timeout,
    false,
  );
};
