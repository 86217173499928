import {
  SET_CODE_AGENT,
  SET_PASSWORD,
  FETCH_CONNEXION,
  RECEIVE_CONNEXION,
  RECEIVE_CONNEXION_FAILURE,
} from '../actions/ActionTypes';

const initialState = {
  name: '',
  numero: null,
  tickets: [],
  isFetching: false,
  isLoading: false,
  fetched: false,
  fetchingAmount: false,
  step: 1,
};

// eslint-disable-next-line default-param-last
export default function papReducer(prev = initialState, action) {
  let state;
  switch (action.type) {
    case SET_CODE_AGENT: {
      state = {
        ...prev,
      };
      break;
    }
    case SET_PASSWORD: {
      state = {
        ...prev,
      };
      break;
    }
    case RECEIVE_CONNEXION: {
      state = {
        ...prev,
        fetchingAmount: false,
        isFetching: false,
        isLoading: false,
        fetched: true,
        tickets: action.res,
        step: 2,
      };
      break;
    }
    case FETCH_CONNEXION: {
      state = {
        ...prev,
        isLoading: true,
        fetchingAmount: true,
        error: null,
      };
      break;
    }
    case RECEIVE_CONNEXION_FAILURE: {
      const actionError = action.error;
      let errorPartner = false;
      let errorWs = null;
      if (actionError.response.body !== undefined) {
        errorWs = actionError.response.body;
        errorPartner = errorWs.partner || false;
      } else {
        // code error
        /*
         * server can send back two types of JSON
         * 1. error code and label are contained inside the response within an error object
         * (such is the case of PenaltyError responses)
         *   {"body" : {"error": {"code": "status code", "label": "status value"}}}
         *
         * 2. error code and label are directly children of body
         * (such is the case of GlobalAvailability responses
         *   {"body" : {"code": "status code", "label": "status value"}}
         *
         * so we need to check if the body has an error object or not
        */
        errorWs = JSON.parse(actionError.message);
        errorPartner = errorWs.partnerError || false;
        // check the kind of error we have
        errorWs = errorWs.error !== undefined ? errorWs.error : errorWs;
      }
      // add isPartner info to errorWs
      errorWs = {
        ...errorWs,
        isPartnerError: errorPartner,
      };

      state = {
        ...prev,
        isFetching: false,
        isLoading: false,
        fetched: false,
        error: errorWs,
        step: prev.step,
        tickets: [],
      };
      break;
    }
    default:
      state = prev;
  }
  return state;
}
