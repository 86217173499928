import React from 'react';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import HeaderBlock from 'components/HeaderBlock/HeaderBlock';
import Step1 from 'components/Steps/Step1';
import Step2 from 'components/Steps/Step2';
import Step3 from 'components/Steps/Step3';

const Steps = ({
  intl, onSubmit, onSubmit2, onSubmit3, fetchWithToken, state, changeInputPenalty, changeInputName,
  changeInputNumero, isLoading, onCkeckAmount, goBack, errorWS, onChangeNewAmount,
  changeInputEmail, lang, handleLang, handleStyle, onBlurNewAmount, onCkeckAmountAll, ivrId,
  blurOnEnter, onCallRequestPaymentCheck, fetchToolsPayment, handleIncomingSelected,
  handleOutcomingSelected,
}) => {
  // get path parameter and pass it down to Step2
  const { token } = useParams();

  return (
    <>
      <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
      <main role="main" id="main-content" className="main-content">
        <section className="container" style={{ marginTop: '70px' }}>
          <div className="main-div">
            <HeaderBlock intl={intl} step={state.step} />
            {isLoading
              && (
                <div>{intl.formatMessage({ id: 'step.loading' })}</div>
              )}
            {(state.tickets.length === 0 && token === undefined)
              && (
                <Step1
                  changeLoadedToken={fetchWithToken}
                  onSubmit={onSubmit}
                  state={state}
                  changeInputName={changeInputName}
                  changeInputNumero={changeInputNumero}
                  changeInputPenalty={changeInputPenalty}
                  errorWS={errorWS}
                />
              )}
            {((state.tickets.length > 0 || token !== undefined) && ivrId === '')
              && (
                <Step2
                  fetchWithToken={fetchWithToken}
                  token={token}
                  onSubmit2={onSubmit2}
                  state={state}
                  tickets={state.tickets}
                  onCkeckAmount={onCkeckAmount}
                  amountTotal={state.amount}
                  nbTicketCheck={state.nbTicketCheck}
                  goBack={goBack}
                  onChangeNewAmount={onChangeNewAmount}
                  changeInputEmail={changeInputEmail}
                  onBlurNewAmount={onBlurNewAmount}
                  blurOnEnter={blurOnEnter}
                  errorWS={errorWS}
                  onCkeckAmountAll={onCkeckAmountAll}
                  handleIncomingSelected={handleIncomingSelected}
                  handleOutcomingSelected={handleOutcomingSelected}
                />
              )}
            {(ivrId.length > 0 && (state.tickets.length > 0 || token !== undefined))
                && (
                <Step3
                  ivrId={ivrId}
                  onCallRequestPaymentCheck={onCallRequestPaymentCheck}
                  fetchTools={fetchToolsPayment}
                  state={state}
                  intl={intl}
                  onSubmit3={onSubmit3}
                />
                )}
          </div>
        </section>
      </main>
      <Footer lang={lang} handleLang={handleLang} intl={intl} />
    </>
  );
};

Steps.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  fetchWithToken: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSubmit2: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  changeInputName: PropTypes.func.isRequired,
  changeInputNumero: PropTypes.func.isRequired,
  changeInputPenalty: PropTypes.func.isRequired,
  changeInputEmail: PropTypes.func.isRequired,
  step: PropTypes.number,
  errorWS: PropTypes.shape(),
  isLoading: PropTypes.bool,
  onCkeckAmount: PropTypes.func.isRequired,
  onChangeNewAmount: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
  onBlurNewAmount: PropTypes.func.isRequired,
  onCkeckAmountAll: PropTypes.func.isRequired,
  blurOnEnter: PropTypes.func.isRequired,
  ivrId: PropTypes.string.isRequired,
  onCallRequestPaymentCheck: PropTypes.func.isRequired,
  fetchToolsPayment: PropTypes.func.isRequired,
  onSubmit3: PropTypes.func.isRequired,
  handleIncomingSelected: PropTypes.func.isRequired,
  handleOutcomingSelected: PropTypes.func.isRequired,
};

Steps.defaultProps = {
  step: 1,
  isLoading: false,
  errorWS: {},
  fetchWithToken: () => { },
};

export default injectIntl(Steps);
