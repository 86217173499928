/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import { Component } from 'react';
import { PropTypes, func } from 'prop-types';
import {
  DEFAULT_ENDPOINT, DEFAULT_TIMEOUT,
} from 'services/consts';

export default function hoc() {
  const PaymentCheck = class PaymentCheck extends Component {
    constructor(props) {
      super(props);
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('btk');
      const lang = searchParams.get('lang');
      sessionStorage.setItem('lang', lang);
      sessionStorage.removeItem('resume');
      this.state = {
        codeagent: '',
        password: '',
        errors: [],
        codeAgentError: false,
        passwordError: false,
        endpoint: DEFAULT_ENDPOINT,
      };
      this.props.fetch('Bearer '.concat(token), this.state.endpoint, DEFAULT_TIMEOUT);
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.error !== prevState.errors) {
        return {
          errorWS: nextProps.error,
        };
      }
      return null;
    }

    submit = (event) => {
      event.preventDefault();
      this.validation();
    };

    render() {
      return ('');
    }
  };

  PaymentCheck.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    fetch: PropTypes.func.isRequired,
  };

  PaymentCheck.defaultProps = {
    error: {},
  };

  return PaymentCheck;
}
