/**
 * Action types
 */
export const SET_STEP = 'SET_STEP';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SEARCH_ERR_EMPTY = 'SEARCH_ERR_EMPTY';
export const ERR_404 = 'ERR_404'; // Not found
export const ERR_503 = 'ERR_503'; // Deactivated MIV
export const ERR_401 = 'ERR_401'; // Unauthorized
export const ERR_504 = 'ERR_504'; // Time out
export const FETCH_AMOUNT = 'FETCH_AMOUNT';
export const FETCH_AMOUNT_WITH_TOKEN = 'FETCH_AMOUNT_WITH_TOKEN';
export const RECEIVE_AMOUNT = 'RECEIVE_AMOUNT';
export const RECEIVE_AMOUNT_WITH_TOKEN = 'RECEIVE_AMOUNT_WITH_TOKEN';
export const RECEIVE_AMOUNT_FAILURE = 'RECEIVE_AMOUNT_FAILURE';
export const RECEIVE_AMOUNT_WITH_TOKEN_FAILURE = 'RECEIVE_AMOUNT_WITH_TOKEN_FAILURE';
export const FETCH_CONNEXION = 'FETCH_CONNEXION';
export const RECEIVE_CONNEXION = 'RECEIVE_CONNEXION';
export const RECEIVE_CONNEXION_FAILURE = 'RECEIVE_CONNEXION_FAILURE';
export const SET_PENALTY = 'SET_PENALTY';
export const SET_NAME = 'SET_NAME';
export const SET_NUMERO = 'SET_NUMERO';
export const RESET_TICKET = 'RESET_TICKET';
export const SET_CODE_AGENT = 'SET_CODE_AGENT';
export const SET_PASSWORD = 'SET_PASSWORD';
export const RECEIVE_IVR_ID = 'RECEIVE_IVR_ID';
export const UPDATE_IVR_ID = 'UPDATE_IVR_ID';
