import 'polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { overloadConf } from './utils/config';
import logger from './utils/logger';
import App from './main/AppExtends';
import fetchConfiguration from './services/fetchConfiguration';

const getConfigAndWordings = (env) => Promise.all([
  fetchConfiguration('application.json', env),
  fetchConfiguration('i18n/fr.json', env),
  fetchConfiguration('i18n/en.json', env),
]);

const initApp = (messages, wsConfig) => {
  overloadConf(wsConfig);
  const container = document.getElementById('root');
  createRoot(container).render(<App messages={messages} lang="fr" />);
};

const configAndWordingsHandler = ([config, fr, en]) => initApp({ fr, en }, config);

getConfigAndWordings(process.env.REACT_APP_EXTERNAL_CONFIG)
  .then(configAndWordingsHandler)
  .catch((error) => logger(error));
