export const parseAmount = (amountStr) => parseFloat(String(amountStr).replace(/[,.](\d*)$/, '.$1'));

export default (amount) => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
};
