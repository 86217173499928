import compose from 'lodash/flowRight';
import withGlobalState from 'hoc/withGlobalState';
import withStyleSwitch from 'hoc/withStyleSwitch';
import {
  INVERTED_CONTRAST,
  REINFORCED_CONTRAST,
  DEFAULT_CONTRAST,
} from 'utils/consts';

import defaultColorStyle from 'assets/scss/style-default-colors.scss';
import higherContrastStyle from 'assets/scss/style-higher-contrast.scss';
import intervertedContrastStyle from 'assets/scss/style-interverted-contrast.scss';

import App from './App';

const initialState = {
  lang: 'fr',
  contrast: 'default',
};

export default compose(
  withGlobalState(initialState),
  withStyleSwitch({
    [REINFORCED_CONTRAST]: higherContrastStyle,
    [INVERTED_CONTRAST]: intervertedContrastStyle,
    [DEFAULT_CONTRAST]: defaultColorStyle,
  }, 'contrast'),
)(App);
