import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import logo from 'assets/images/logo-sncf.svg';
import frenchFlag from 'assets/images/french.svg';
import englishFlag from 'assets/images/english.svg';

class Footer extends React.PureComponent {
  onChangeLanguage = (e) => {
    e.preventDefault();
    this.props.handleLang(e.currentTarget.id);
  };

  render() {
    const { lang, intl } = this.props;
    const flagToDisplay = lang === 'en' ? englishFlag : frenchFlag;
    return (
      <footer id="footer" className="footer" role="contentinfo">
        <div id="footer-illustrated" className="footer-illustrated">
          <ul className="footer-illustrated-list clearfix container">
            <li className="footer-illustrated-item">
              <a
                href={intl.formatMessage({ id: 'link.footer.contact' })}
                title="Contact - Nouvelle fenêtre"
                aria-label="Contact - Nouvelle fenêtre"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-illustrated-link"
              >
                <span className="footer-illustrated-img snc-icon-large-information-outline" aria-hidden="true" />
                <p className="footer-illustrated-content">
                  <span className="footer-illustrated-txt1">
                    <FormattedMessage id="footer.contact_us" />
                    <span className="snc-icon-arrow" aria-hidden="true" />
                  </span>
                  <span className="footer-illustrated-txt2">
                    <FormattedMessage id="footer.got_a_question" />
                  </span>
                </p>
              </a>
            </li>
            <li className="footer-illustrated-item">
              <a href={intl.formatMessage({ id: 'link.footer.delays' })} title="Retard - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer" className="footer-illustrated-link">
                <span className="footer-illustrated-img snc-icon-large-clock" aria-hidden="true" />
                <p className="footer-illustrated-content">
                  <span className="footer-illustrated-txt1">
                    <FormattedMessage id="footer.delays.title" />
                    <span className="snc-icon-arrow" aria-hidden="true" />
                  </span>
                  <span className="footer-illustrated-txt2">
                    <FormattedMessage id="footer.delays.description" />
                  </span>
                </p>
              </a>
            </li>
            <li className="footer-illustrated-item">
              <a href={intl.formatMessage({ id: 'link.footer.question' })} title="Questions / Réponses - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer" className="footer-illustrated-link">
                <span className="footer-illustrated-img snc-icon-large-conversation" aria-hidden="true" />
                <p className="footer-illustrated-content">
                  <span className="footer-illustrated-txt1">
                    <FormattedMessage id="footer.question_answer" />
                    <span className="snc-icon-arrow" aria-hidden="true" />
                  </span>
                  <span className="footer-illustrated-txt2">
                    <FormattedMessage id="footer.sncf_responds" />
                  </span>
                </p>
              </a>
            </li>
          </ul>
        </div>
        <hr className="footer-separator" />
        <div className="container">
          <div id="footer-social" className="footer-social">
            <div className="grid-1-small-2 has-gutter clearfix">
              <div className="one-quarter">
                <div className="col-footer-logo">
                  <img src={logo} alt="SNCF" width="211" height="41" className="footer-logo-sncf" />
                </div>
                <div className="col-footer-accessibility footer-accessibility-container">
                  <div className="footer-accessibility-content">
                    <p><FormattedMessage id="footer.accessibility.block.title" /></p>
                    <p><FormattedMessage id="footer.accessibility.block.text1" /></p>
                    <p><FormattedMessage id="footer.accessibility.block.text2" /></p>
                  </div>
                </div>
              </div>
              <div className="two-thirds col-footer-list-links">
                <ul className="footer-list-links clearfix">
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.profil' })}>
                      <FormattedMessage id="footer.profil" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.gouvernance' })}>
                      <FormattedMessage id="footer.gouvernance" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.dev.durable' })}>
                      <FormattedMessage id="footer.dev.durable" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.innovation' })}>
                      <FormattedMessage id="footer.innovation" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.fondation' })}>
                      <FormattedMessage id="footer.fondation" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.design' })}>
                      <FormattedMessage id="footer.design" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.sncf.reseau' })}>
                      <FormattedMessage id="footer.sncf.reseau" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.sncf.stations' })}>
                      <FormattedMessage id="footer.sncf.stations" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.sncf.travel' })}>
                      <FormattedMessage id="footer.sncf.travel" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.rails.europe' })}>
                      <FormattedMessage id="footer.rails.europe" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.keolis' })} title="Keolis - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="footer.keolis" />
                      <span className="snc-icon-external-link" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.geodis' })} title="Geodis - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="footer.geodis" />
                      <span className="snc-icon-external-link" aria-hidden="true" />
                    </a>
                  </li>
                  {lang === 'en'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.who.we.are' })}>
                      <FormattedMessage id="footer.who.we.are" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'en'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.gouvernance' })}>
                      <FormattedMessage id="footer.gouvernance" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.journalist' })}>
                      <FormattedMessage id="footer.journalist" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  {lang === 'en'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.fondation' })}>
                      <FormattedMessage id="footer.fondation" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.investor' })}>
                      <FormattedMessage id="footer.investor" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  {lang === 'fr'
                  && (
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.employment' })}>
                      <FormattedMessage id="footer.employment" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                  )}
                  <li className="menu-item">
                    <a href={intl.formatMessage({ id: 'link.footer.suppliers' })}>
                      <FormattedMessage id="footer.suppliers" />
                      <span className="snc-icon-arrow" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="footer-quick-links" className="footer-quick-links">
            <ul className="footer-list-links-bottom">
              <li className="menu-item">
                <a href={intl.formatMessage({ id: 'link.footer.accessibility' })}>
                  <FormattedMessage id="footer.accessibility" />
                </a>
              </li>
              <li className="menu-item">
                <a href={intl.formatMessage({ id: 'link.footer.sitemap' })}>
                  <FormattedMessage id="footer.sitemap" />
                </a>
              </li>
              <li className="menu-item">
                <a href={intl.formatMessage({ id: 'link.footer.legal_notices' })}>
                  <FormattedMessage id="footer.legal_notices" />
                </a>
              </li>
              <li className="menu-item">
                <a href={intl.formatMessage({ id: 'link.footer.personal_data' })}>
                  <FormattedMessage id="footer.personal_data" />
                </a>
              </li>
              <li className="menu-item">
                <a href={intl.formatMessage({ id: 'link.footer.video_and_security' })}>
                  <FormattedMessage id="footer.video_and_security" />
                </a>
              </li>
            </ul>
            <ul className="footer-list-langues">
              <li>
                <img src={flagToDisplay} alt="" width="26" height="26" className="footer-flag" />
                <a hrefLang="fr" title={`${lang === 'fr' ? intl.formatMessage({ id: 'footer.title.selected.language' }) : ''}`} href="/" onClick={this.onChangeLanguage} className={`${lang === 'fr' ? 'active' : ''}`} lang="fr" id="fr"><span>Fr</span></a>
              </li>
              <li>
                <a hrefLang="en" title={`${lang === 'en' ? intl.formatMessage({ id: 'footer.title.selected.language' }) : ''}`} href="/" onClick={this.onChangeLanguage} className={`${lang === 'en' ? 'active' : ''}`} lang="en" id="en"><span>En</span></a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  lang: PropTypes.string,
  handleLang: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
};

Footer.defaultProps = {
  lang: 'fr',
};

export default Footer;
