/**
 * Action creator generator used for departures and arrivals.
 */
import ws from 'services/ws';
import {
  SET_STEP, FETCH_AMOUNT, RECEIVE_AMOUNT, RECEIVE_AMOUNT_FAILURE, RESET_TICKET,
  SET_NAME, SET_NUMERO, SET_PENALTY, FETCH_AMOUNT_WITH_TOKEN, RECEIVE_AMOUNT_WITH_TOKEN,
  RECEIVE_AMOUNT_WITH_TOKEN_FAILURE, UPDATE_IVR_ID,
} from './ActionTypes';

export const requestAmount = (
  penalty,
  name,
  numero,
) => ({
  type: FETCH_AMOUNT,
  penalty,
  name,
  numero,
});

export const requestAmountWithToken = (token) => ({
  type: FETCH_AMOUNT_WITH_TOKEN,
  token,
});

export const receiveAmount = (
  penalty,
  name,
  numero,
  res,
  msgError,
) => ({
  type: RECEIVE_AMOUNT,
  penalty,
  name,
  numero,
  res,
  msgError,
});

export const receiveAmountWithToken = (token, res, msgError) => ({
  type: RECEIVE_AMOUNT_WITH_TOKEN,
  token,
  res,
  msgError,
});

/*
 * along with error information, we dispatch if the
 * API called a partner service
 * by default, it will be false
*/
export const receiveFailureAmount = (error) => ({
  type: RECEIVE_AMOUNT_FAILURE,
  error,
  isPartnerError: error.partner || false,
});

export const receiveAmountWithTokenFailure = (error) => ({
  type: RECEIVE_AMOUNT_WITH_TOKEN_FAILURE,
  error,
  isPartnerError: error.partner || false,
});

function callRetrieveDueAmount(
  penalty,
  name,
  numero,
  dispatch,
  endpoint,
  timeout,
  alreadyCalled = false,
) {
  ws(endpoint, timeout).retrieveDueAmount(
    penalty,
    name,
    numero,
  )
    .then(({ res, msgError, authorization }) => {
      sessionStorage.setItem('token.client', authorization);
      dispatch(receiveAmount(
        penalty,
        name,
        numero,
        res,
        msgError,
      ));
    }, (error) => {
      if (!alreadyCalled
        && sessionStorage.getItem('token.agent')
      ) {
        const errMsg = error.response.body;
        // check if agent is logged in to prevent renewing
        // client token
        if (errMsg.error.code === 401 && errMsg.error.label === 'UNAUTHORIZED') {
          ws(endpoint, timeout)
            .refreshAgentToken('token.agent')
            .then(({ head }) => {
              // set new token in local storage
              sessionStorage.setItem('token.agent', head.authorization);
              callRetrieveDueAmount(
                penalty,
                name,
                numero,
                dispatch,
                endpoint,
                timeout,
                true,
              );
            }, (refreshTokenExpiredError) => {
              // refresh token expired, redirect to homepage
              const refreshError = refreshTokenExpiredError.response.body;
              sessionStorage.removeItem('token.agent');
              sessionStorage.setItem('token.agent.expired', refreshError.message);
              window.location.replace('/');
            });
        } else {
          dispatch(receiveFailureAmount(error));
        }
      } else {
        // token not expired
        dispatch(receiveFailureAmount(error));
      }
    });
}

export const fetchAmount = (
  penalty,
  name,
  numero,
  dispatch,
  endpoint,
  timeout,
) => {
  requestAmount(
    penalty,
    name,
    numero,
  );
  callRetrieveDueAmount(
    penalty,
    name,
    numero,
    dispatch,
    endpoint,
    timeout,
  );
};

function callretrieveDueAmountWithToken(
  token,
  dispatch,
  endpoint,
  timeout,
  alreadyCalled = false,
) {
  ws(endpoint, timeout)
    .retrieveDueAmountWithToken(token)
    .then(({ res, msgError, authorization }) => {
      sessionStorage.setItem('token.client', authorization);

      return dispatch(receiveAmountWithToken(token, res, msgError));
    }, (error) => {
      if (!alreadyCalled
          && sessionStorage.getItem('token.agent')
      ) {
        // error is of type native Error javascript
        const errMsg = error.response.body;
        // check if agent is logged in to prevent renewing
        // client token
        if (errMsg.error.code === 401 && errMsg.error.label === 'UNAUTHORIZED') {
          ws(endpoint, timeout)
            .refreshAgentToken('token.agent')
            .then(({ head }) => {
              // set new token in local storage
              sessionStorage.setItem('token.agent', head.authorization);
            }, (refreshTokenExpiredError) => {
              // refresh token expired, redirect to homepage
              const refreshError = refreshTokenExpiredError.response.body;
              sessionStorage.removeItem('token.agent');
              sessionStorage.setItem('token.agent.expired', refreshError.message);
              window.location.replace('/');
            });
          callretrieveDueAmountWithToken(
            token,
            dispatch,
            endpoint,
            timeout,
            true,
          );
        } else {
          dispatch(receiveAmountWithTokenFailure(error));
        }
      } else {
        dispatch(receiveAmountWithTokenFailure(error));
      }
    })
    .catch((error) => {
      dispatch(receiveAmountWithTokenFailure(error));
    });
}

export const fetchAmountWithToken = (
  token,
  dispatch,
  endpoint,
  timeout,
) => {
  requestAmountWithToken(token);
  callretrieveDueAmountWithToken(
    token,
    dispatch,
    endpoint,
    timeout,
  );
};

export const requestCheckoutAmount = (
  penalty,
  name,
  numero,
) => ({
  type: FETCH_AMOUNT,
  penalty,
  name,
  numero,
});

export const checkoutFailureAmount = (error) => ({
  type: RECEIVE_AMOUNT_FAILURE,
  error,
});

export const updateIvrId = (res, ivrId) => ({
  type: UPDATE_IVR_ID,
  res,
  ivrId,
});

function callCheckoutDueAmount(
  tickets,
  email,
  isPaymentPage,
  lang,
  dispatch,
  endpoint,
  timeout,
  alreadyCalled = false,
) {
  ws(endpoint, timeout).checkoutDueAmount(
    email,
    isPaymentPage,
    lang,
    tickets,
  ).then(({ res, ivrId }) => {
    if (res === undefined && ivrId !== undefined && ivrId.length > 0) {
      dispatch(updateIvrId(res, ivrId));
      return;
    }
    // contrevenant
    window.location.replace(res);
  }, (error) => {
    if (!alreadyCalled
      && sessionStorage.getItem('token.agent')
    ) {
      const errMsg = error.response.body;
      // check if agent is logged in to prevent renewing
      // client token
      if (errMsg.error.code === 401 && errMsg.error.label === 'UNAUTHORIZED') {
        ws(endpoint, timeout)
          .refreshAgentToken('token.client')
          .then(({ head }) => {
            sessionStorage.setItem('token.client', head.authorization);
            callCheckoutDueAmount(
              tickets,
              email,
              lang,
              dispatch,
              endpoint,
              timeout,
              true,
            );
          }, (refreshTokenExpiredError) => {
            // refresh token expired, redirect to homepage
            const refreshError = refreshTokenExpiredError.response.body;
            sessionStorage.removeItem('token.agent');
            sessionStorage.setItem('token.agent.expired', refreshError.message);
            window.location.replace('/');
          });
      } else {
        // token expired error
        dispatch(checkoutFailureAmount(error));
      }
    } else {
      // check if client token is expired here
      const parseError = error.response.body;
      if (parseError
        && parseError.error.code === 401
        && parseError.error.label === 'UNAUTHORIZED'
      ) {
        // client token expired
        sessionStorage.setItem('token.client.expired', 'Session expirée');
        window.location.replace('/');
      }
      // token not expired, different error
      dispatch(checkoutFailureAmount(error));
    }
  });
}

export const fetchCheckoutAmount = (
  tickets,
  email,
  isPaymentPage,
  lang,
  dispatch,
  endpoint,
  timeout,
) => {
  requestCheckoutAmount(
    tickets,
    email,
    isPaymentPage,
    lang,
  );
  callCheckoutDueAmount(
    tickets,
    email,
    isPaymentPage,
    lang,
    dispatch,
    endpoint,
    timeout,
  );
};

export const receiveIvrIdForAgent = (
  tickets,
  email,
  isPaymentPage,
  lang,
  dispatch,
  endpoint,
  timeout,
) => {
  requestCheckoutAmount(
    tickets,
    email,
    isPaymentPage,
    lang,
  );
  callCheckoutDueAmount(
    tickets,
    email,
    isPaymentPage,
    lang,
    dispatch,
    endpoint,
    timeout,
  );
};

export const setPenalty = (
  penalty = '',
) => ({
  type: SET_PENALTY,
  penalty,
});

export const setName = (
  name = '',
) => ({
  type: SET_NAME,
  name,
});

export const setNumero = (
  numero = '',
) => ({
  type: SET_NUMERO,
  numero,
});

export const setStep = (
  step = '',
) => ({
  type: SET_STEP,
  step,
});

export const resetTicket = () => ({
  type: RESET_TICKET,
});


