import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import frenchFlag from 'assets/images/french.svg';
import englishFlag from 'assets/images/english.svg';
import accessibility from '../../../utils/accessibility';

class AccessibilityBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageHidden: true,
      accessibilityHidden: true,
    };
  }

  componentDidMount() {
    accessibility.initHeader();
  }

  onClickLanguage = () => {
    this.setState((prevState) => ({
      languageHidden: !prevState.languageHidden,
      accessibilityHidden: true,
    }));
  };

  onClickAccebility = () => {
    this.setState((prevState) => ({
      accessibilityHidden: !prevState.accessibilityHidden,
      languageHidden: true,
    }));
  };

  onChangeLanguage = (e) => {
    e.preventDefault();
    this.props.handleLang(e.target.id);
    this.onClickLanguage();
  };

  onChangeContraste = (e) => {
    e.preventDefault();
    this.props.handleStyle(e.target.id);
    this.onClickAccebility();
  };

  render() {
    return (
      <div className="bar-header-languages-accessibility">
        <div className="container">
          <p className="bar-header-intro">
            <FormattedMessage id="accessibility_bar_message" />
          </p>
          <div id="block-accessibility" className="block-accessibility">
            <div className="btn-accessibility-title js-expandmore" data-hideshow-prefix-class="animated">
              <button type="button" className="animated-expandmore__button js-expandmore-button" id="label_expand_1" aria-controls="expand_1" aria-expanded="false" onClick={this.onClickAccebility}>
                <FormattedMessage id="accessibility" />
                <span className="snc-icon-arrow" aria-hidden="true" />
              </button>
            </div>
            <div className="popin-accessibility js-to_expand js-to_escape animated-expandmore__to_expand" data-hidden={this.state.accessibilityHidden} id="expand_1" data-labelledby="label_expand_1">
              <button type="button" className="btn-popin-close" id="close-label_expand_1" onClick={this.onClickAccebility}>
                <span className="visually-hidden">Fermer</span>
                <span className="snc-icon-circle-close" aria-hidden="true" />
              </button>
              <div className="block-popin-accessibility">
                <p id="accessibility-contrasts" className="title-accessibility"><FormattedMessage id="Contrasts" /></p>
                <div className="block-btn-accessibility block-btn-accessibility-contrasts">
                  <button
                    type="button"
                    className={`btn-accessibility btn-contrast btn-contrast-default ${sessionStorage.getItem('style') === 'default' ? 'active' : null}`}
                    aria-describedby="accessibility-contrasts"
                    onClick={this.onChangeContraste}
                    id="default"
                  >
                    <FormattedMessage id="Default" />
                  </button>
                  <button
                    type="button"
                    className={`btn-accessibility btn-contrast btn-contrast-higher ${sessionStorage.getItem('style') === 'reinforced' ? 'active' : null}`}
                    aria-describedby="accessibility-contrasts"
                    onClick={this.onChangeContraste}
                    id="reinforced"
                  >
                    <FormattedMessage id="Reinforce" />
                  </button>
                  <button
                    type="button"
                    className={`btn-accessibility btn-contrast btn-contrast-inverted ${sessionStorage.getItem('style') === 'inverted' ? 'active' : null}`}
                    aria-describedby="accessibility-contrasts"
                    onClick={this.onChangeContraste}
                    id="inverted"
                  >
                    <FormattedMessage id="Inverse" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="block-languages">
            <div className="btn-languages js-expandmore" data-hideshow-prefix-class="animated">
              <button type="button" className="animated-expandmore__button js-expandmore-button" id="label_expand_2" aria-controls="expand_2" aria-expanded="false" onClick={this.onClickLanguage}>
                <FormattedMessage id="Language" />
                <span className="lang" aria-hidden="true">
                  {this.props.lang.toUpperCase()}
                </span>
                <span className="visually-hidden"><FormattedMessage id="Language" /></span>
                <span className="snc-icon-arrow" aria-hidden="true" />
              </button>
            </div>
            <div className="popin-languages js-to_expand animated-expandmore__to_expand" data-hidden={this.state.languageHidden} id="expand_2" data-labelledby="label_expand_2">
              <button type="button" className="btn-popin-close" id="close-label_expand_2" onClick={this.onClickLanguage}>
                <span className="visually-hidden">Fermer</span>
                <span className="snc-icon-circle-close" aria-hidden="true" />
              </button>
              <ul className="popin-languages-list">
                <li className="popin-languages-item">
                  <button type="button" className={`popin-languages-button ${this.props.lang === 'fr' ? 'active' : null}`} lang="fr" onClick={this.onChangeLanguage} id="fr">
                    <img className="popin-languages-flag" width="30" height="30" src={frenchFlag} alt="" id="fr" />
                    <span className="popin-languages-link-text" id="fr">Français</span>
                  </button>
                </li>
                <li className="popin-languages-item">
                  <button type="button" className={`popin-languages-button ${this.props.lang === 'en' ? 'active' : null}`} lang="en" onClick={this.onChangeLanguage} id="en">
                    <img className="popin-languages-flag" width="30" height="30" src={englishFlag} alt="" id="en" />
                    <span className="popin-languages-link-text" id="en">English</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccessibilityBar.propTypes = {
  lang: PropTypes.string,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

AccessibilityBar.defaultProps = {
  lang: 'fr',
};

export default AccessibilityBar;
