import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';

const HeaderBlock = ({ intl, step }) => (
  <>
    <h1 className="main-title-ctr">{intl.formatMessage({ id: 'step.main.title' })}</h1>
    {step === 0 && <p className="main-subtitle-ctr">{intl.formatMessage({ id: 'step.main.subtitle.connexion' })}</p>}
    {step !== 0 && (
      <>
        <p className="main-subtitle-ctr">{intl.formatMessage({ id: 'step.main.subtitle' })}</p>
        <ol className="main-breadcrum-ctr">
          <li className={`breadcrum-item-ctr ${step >= 1 ? 'activated' : ''}`}>
            {intl.formatMessage({ id: 'step.breadcrumb_1' })}
            <span className="snc-icon-arrow arrow-breadcrumb breadcrum-arrow-ctr" aria-hidden="true" />
          </li>
          <li className={`breadcrum-item-ctr ${step >= 2 ? 'activated' : ''}`}>
            {intl.formatMessage({ id: 'step.breadcrumb_2' })}
            <span className="snc-icon-arrow arrow-breadcrumb breadcrum-arrow-ctr" aria-hidden="true" />
          </li>
          <li className={`breadcrum-item-ctr ${step >= 3 ? 'activated' : ''}`}>
            {intl.formatMessage({ id: 'step.breadcrumb_3' })}
            <span className="snc-icon-arrow arrow-breadcrumb breadcrum-arrow-ctr" aria-hidden="true" />
          </li>
          <li className={`breadcrum-item-ctr ${step >= 4 ? 'activated' : ''}`}>
            {intl.formatMessage({ id: 'step.breadcrumb_4' })}
          </li>
        </ol>
        <hr />
      </>
    )}
  </>
);

HeaderBlock.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  step: PropTypes.number,
};

HeaderBlock.defaultProps = {
  step: 1,
};

export default injectIntl(HeaderBlock);
