import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import formatAmount from 'utils/formatAmount';
import { validateEmptyValue } from 'services/validators';

import loading from 'assets/images/loader.gif';
import { useNavigate } from 'react-router-dom';

const Step2 = ({
  intl, state, tickets, onCkeckAmount, amountTotal, nbTicketCheck, goBack,
  onSubmit2, onChangeNewAmount, changeInputEmail, onBlurNewAmount, onCkeckAmountAll,
  blurOnEnter, fetchWithToken, token, handleIncomingSelected, handleOutcomingSelected,
}) => {
  const navigate = useNavigate();

  // initial content is loader since we haven't received
  // ticket infos from the server
  let content = (
    <div className="step-loading">
      <img src={loading} alt="Loading.." className="step-loading-img" />
    </div>
  );

  // called only when the token changes
  // or when the endpoint has been fetched
  // in the StepsHOC.js constructor
  // hide ctr back button if user comes with token
  const hideCtrBackButton = token !== null ? 'btn-ctr-back-hidden' : '';
  useEffect(() => {
    if (token !== null && state.fetchedEndpoint) {
      Promise.all([])
        .then(() => {
          fetchWithToken(token);
        });
    }
  }, [token, state.fetchedEndpoint]);

  // className to change list style and hide list title
  // when error is from partner
  let noPuces = '';
  let noTitle = '';
  if (validateEmptyValue(state.errorWS)
  && (state.errorWS.isPartnerError || (state.errorWS.code === 503 && state.errorWS.label === 'ctr.error.unavailable'))) {
    noPuces += 'no-puces';
    noTitle += 'no-title';
  }

  if (state.tickets instanceof Array && state.tickets.length > 0) {
    content = (
      <>
        <h2 className="subtitle-step-name-num">{intl.formatMessage({ id: 'step2.main.subtitle' })}</h2>
        <form onSubmit={onSubmit2}>
          {validateEmptyValue(state.errorWS)
            && (
              <div
                id="form-error-alert-block"
                className="form-error-block-txt"
                role="alert"
                aria-live="assertive"
                tabIndex="-1"
              >
                <FormattedMessage id="ctr.search_error">
                  {(text) => (<p className={`form-error-block-title ${noTitle}`}>{text}</p>)}
                </FormattedMessage>
                <ul className={`form-error-block-list ${noPuces}`}>
                  {validateEmptyValue(state.errorWS)
                    && (
                      <li key={state.errorWS.code}>
                        <FormattedMessage id={state.errorWS.label} />
                      </li>
                    )}
                </ul>
              </div>
            )}
          {/* The table in it's desktop version */}
          <div className="ctr-tickets-large">
            <div role="table" aria-labelledby="ticket-table-title" className="ctr-table">
              <div role="rowgroup">
                <div role="row" className="ctr-table-row ctr-table-row-header">
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">
                    <label htmlFor="checkbox-all" className="ctr-table-checkbox-all">
                      <input
                        className="ctr-table-checkbox-input-all"
                        type="checkbox"
                        id="checkbox-all"
                        name="ticket-table-checkbox-all"
                        onClick={onCkeckAmountAll}
                        defaultChecked="true"
                      />
                      <span className="ctr-table-checkbox--label-all" />
                    </label>
                    {intl.formatMessage({ id: 'step2.table.select' })}
                  </span>
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.ctr' })}</span>
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.amount' })}</span>
                  {sessionStorage.getItem('token.agent') && (<span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.new.amount' })}</span>)}
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.comment' })}</span>
                </div>
              </div>

              <div role="rowgroup" className="ctr-table-content">
                {tickets.map((t, id) => (
                  <div role="row" className="ctr-table-row" key={t.id}>
                    <span role="cell" className="ctr-table-cell">
                      <label htmlFor={`checkbox-${id}`} className="ctr-table-checkbox">
                        <input
                          className="ctr-table-checkbox-input"
                          type="checkbox"
                          id={`checkbox-${id}`}
                          name="ticket-table-checkbox"
                          onClick={onCkeckAmount}
                          checked={t.checked}
                        />
                        <span className="ctr-table-checkbox--label" />
                      </label>
                    </span>
                    <span role="cell" className="ctr-table-cell">{t.ticketId}</span>
                    <span role="cell" className="ctr-table-cell">
                      {formatAmount(t.amount)}
                    </span>
                    {sessionStorage.getItem('token.agent') && (
                      <span role="cell" className="ctr-table-cell">
                        <input id={`newamount-${id}`} className="form-field-with-info form-field-table" value={t.newAmount} onChange={onChangeNewAmount} onBlur={onBlurNewAmount} onKeyDown={blurOnEnter} />
                      </span>
                    )}
                    <span role="cell" className="ctr-table-cell">{t.comment}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End of the table in it's desktop version */}
          {/* The table in it's mobile version */}
          <div className="ctr-tickets-small">
            <ul className="ctr-ticket-list">
              {tickets.map((t, id) => (
                <li className="ctr-ticket-item" key={t.id}>
                  <div role="table" aria-labelledby="ticket-table-title" className="ctr-table">
                    <div role="rowgroup">
                      <div role="row" className="ctr-table-row">
                        <span role="rowheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.select' })}</span>
                        <span role="cell" className="ctr-table-cell">
                          <label htmlFor={`checkboxmobile-${id}`} className="ctr-table-checkbox">
                            <input
                              className="ctr-table-checkbox-input"
                              type="checkbox"
                              id={`checkboxmobile-${id}`}
                              name="ticket-table-checkbox-mobile"
                              onClick={onCkeckAmount}
                              checked={t.checked}
                            />
                            <span className="ctr-table-checkbox--label" />
                          </label>
                        </span>
                      </div>
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.ctr' })}</span>
                        <span role="cell" className="ctr-table-cell">{t.ticketId}</span>
                      </div>
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.amount' })}</span>
                        <span role="cell" className="ctr-table-cell">{formatAmount(t.amount)}</span>
                      </div>
                      {sessionStorage.getItem('token.agent') && (
                        <div role="row" className="ctr-table-row">
                          <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.new.amount' })}</span>
                          <span role="cell" className="ctr-table-cell">
                            <input id={`newamountmobile-${id}`} className="form-field-with-info form-field-table" value={t.newAmount} onChange={onChangeNewAmount} onBlur={onBlurNewAmount} onKeyDown={blurOnEnter} />
                          </span>
                        </div>
                      )}
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'step2.table.comment' })}</span>
                        <span role="cell" className="ctr-table-cell">{t.comment}</span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* End of the table in it's mobile version */}
          {nbTicketCheck > 0
            && (
              <p className="ctr-text">
                {nbTicketCheck}
                {nbTicketCheck === 1 ? intl.formatMessage({ id: 'step2.selectionned.single' }) : intl.formatMessage({ id: 'step2.selectionned.plurial' })}

                <span className="ctr-ticket-total-price">
                  {' '}
                  {formatAmount(amountTotal)}
                </span>
              </p>
            )}
          <div className="ctr-email">
            <label htmlFor="email-ctr-input" className="subtitle-step-name-num">{intl.formatMessage({ id: 'step2.email.subtitle' })}</label>
            <div className="ctr-input-block clearfix">
              <div className="ctr-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper">
                <input
                  type="email"
                  name="email-ctr-input"
                  id="email-ctr-input"
                  className="form-field-with-info form-field"
                  placeholder={intl.formatMessage({ id: 'example.email' })}
                  title={intl.formatMessage({ id: 'step2.input.label.email' })}
                  value={state.email}
                  onChange={changeInputEmail}
                />
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div>
              <button type="button" className={`btn btn-default btn-block btn-ctr-back ${hideCtrBackButton}`} title={intl.formatMessage({ id: 'step.button.back' })} onClick={goBack} disabled={!!token}>
                {intl.formatMessage({ id: 'step.button.back' })}
              </button>
            </div>
            {
              sessionStorage.getItem('token.agent') != null && (
                <div className="btn-group-step-2">
                  <div className="ctr-payment-choice">
                    <div className="ctr-radio-button">
                      <label className="ctr-radio-input-label">
                        <input
                          className="ctr-radio-input"
                          type="radio"
                          name="appel_entrant"
                          checked={state.incomingCall}
                          onChange={handleIncomingSelected}
                        />
                        Appel entrant
                      </label>
                    </div>
                    <div className="ctr-radio-button">
                      <label className="ctr-radio-input-label">
                        <input
                          className="ctr-radio-input"
                          type="radio"
                          name="appel_sortant"
                          checked={state.outcomingCall}
                          onChange={handleOutcomingSelected}
                        />
                        Appel sortant
                      </label>
                    </div>
                  </div>
                  <div className="btn-ctr-next-step-2-container">
                    <button type="submit" className={`btn btn-default btn train-input-btn btn-ctr-next-step-2 ${nbTicketCheck <= 0 && 'disabled'}`} title={intl.formatMessage({ id: 'step.button.next' })} disabled={nbTicketCheck <= 0 || state.isLoading}>
                      {intl.formatMessage({ id: 'step.button.next' })}
                    </button>
                  </div>
                </div>
              )
            }
            {
              sessionStorage.getItem('token.agent') == null && (
              <div className="btn-ctr-next-step-2-container">
                <button type="submit" className={`btn btn-default btn train-input-btn btn-ctr-next-step-2 ${nbTicketCheck <= 0 && 'disabled'}`} title={intl.formatMessage({ id: 'step.button.next' })} disabled={nbTicketCheck <= 0 || state.isLoading}>
                  {intl.formatMessage({ id: 'step.button.next' })}
                </button>
              </div>
              )
            }
          </div>
        </form>
      </>
    );
  }

  if (state.errorWS !== null && navigate !== null) {
    const error = state.errorWS;
    if (error.code < 500 || (error.code === 500 && error.label === 'ctr.error.penalty.null')) {
      /*
       * when server error (site indisponible, erreur HUP, erreur NEO)
       * don't redirect to homepage but rather display error
       * on the same step
       *
       * if error is 500 but user arrived with token and NEO is down
       * then redirect to homepage
      */
      navigate('/');
    }
  }

  return content;
};

Step2.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  state: PropTypes.shape().isRequired,
  tickets: PropTypes.arrayOf().isRequired,
  onCkeckAmount: PropTypes.func.isRequired,
  amountTotal: PropTypes.number.isRequired,
  nbTicketCheck: PropTypes.number.isRequired,
  goBack: PropTypes.func.isRequired,
  onSubmit2: PropTypes.func.isRequired,
  onChangeNewAmount: PropTypes.func.isRequired,
  changeInputEmail: PropTypes.func.isRequired,
  onBlurNewAmount: PropTypes.func.isRequired,
  blurOnEnter: PropTypes.func.isRequired,
  errorWS: PropTypes.shape(),
  onCkeckAmountAll: PropTypes.func.isRequired,
  fetchWithToken: PropTypes.func,
  token: PropTypes.string,
  handleIncomingSelected: PropTypes.func.isRequired,
  handleOutcomingSelected: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  errorWS: {},
  fetchWithToken: null,
  token: null,
};

export default injectIntl(Step2);
