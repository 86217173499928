// Url formatting.
export const URL_FORMAT = '{0}/{1}/{2}/{3}/{4}/{5}{6}';
export const URL_SEG_FORMAT = '{0}/{1}/{2}/{3}';
export const URL_LANG_FORMAT = '/{0}/{1}';
export const DEFAULT_LANG = 'fr';
export const TIMETABLES_ROUTE = '/{0}/{1}/{2}/{3}'; // 1=gares
export const ITINERARY_RESULT_ROUTE = '/{0}/{1}?uic1={2}&coordX1={3}&coordY1={4}&uic2={5}&coordX2={6}&coordY2={7}&date={8}&when={9}&label1={10}&label2={11}&typeDepart={12}&typeArrivee={13}&listeCodesMode={14}&origineCP={15}&destinationCP={16}';
export const INTERCALARY_ROUTE_FROM_ITINERARY_SEARCH = '/{0}/{1}?search=itinerary&uic1={2}&coordX1={3}&coordY1={4}&uic2={5}&coordX2={6}&coordY2={7}&date={8}&when={9}&label1={10}&label2={11}&typeDepart={12}&typeArrivee={13}&listeCodesMode={14}&origineCP={15}&destinationCP={16}';
export const DETAIL_ITINERARY_ROUTE = '/{0}/{1}?uic1={2}&coordX1={3}&coordY1={4}&uic2={5}&coordX2={6}&coordY2={7}&date={8}&when={9}&id={10}&typeDepart={11}&typeArrivee={12}&label1={13}&label2={14}&listeCodesMode={15}&origineCP={16}&destinationCP={17}';
export const TRAIN_ROUTE = '/{0}/{1}/{2}?date={3}';
export const INTERCALARY_ROUTE_FROM_TRAIN_SEARCH = '/{0}/{1}?search=train&train={2}&date={3}';
export const TRAIN_SEARCH = '/{0}/{1}?train={2}&date={3}';
export const DETAIL_TRAIN_ROUTE = '/{0}/{1}/{2}?date={3}&codeCirculation={4}&codeZoneArret={5}';
export const STATION_SEARCH = '/{0}/{1}?uic={2}&label={3}';
export const URL_TRAFIC_FORECAST = '/{0}/{1}/{2}';
export const URL_BOOKING = '/{1}/{2}';
export const URL_SEARCH_SHOP = '/{0}/{1}?search={2}&coordX={3}&coordY={4}';
export const URL_STEP_2 = '/step2?name={0}&numero={1}';
export const URL_STEP_3 = '/step3';
export const URL_CHECK_DISPONIBILITY = '/availability';
export const DEFAULT_TIMEOUT = 30000;
export const DEFAULT_ENDPOINT = '/api';

export const PENALTY_INPUT = 'penalty-input';
export const NAME_INPUT = 'name-input';
export const NUMERO_INPUT = 'numero-input';
export const CODE_AGENT_INPUT = 'code-agent-input';
export const PASSWORD_INPUT = 'password-input';
