import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * Composant utilisé pour détecter et rendre le contenu du bouton d'ouverture de l'info-bulle.
 *
 * @param children : le contenu du bouton.
 * @constructor
 */
export const TooltipButton = () => {
};

/**
 * Composant utilisé pour détecter et rendre le contenu de l'info-bulle.
 *
 * @param children : le contenu de l'info-bulle.
 * @constructor
 */
export const TooltipContent = () => {
};

/**
 * Infobulle générique composée d'un bouton externe d'ouverture/fermeture,
 * d'un bouton interne de fermeture, et d'un contenu.
 *
 * La structure à utiliser est la suivante :
 *   <Tooltip>
 *     <TooltipButton>Contenu de mon bouton</TooltipButton>
 *     <TooltipContent>Contenu de mon infobulle</TooltipContent>
 *   </Tooltip>
 *
 * pour obtenir la structure html suivante :
 *   <div>
 *     <button>Contenu de mon bouton</button> // Bouton d'ouverture
 *     <div> // Infobulle
 *       <button /> // Croix de fermeture
 *       <div>
 *         Contenu de l'infobulle
 *       </div>
 *     </div>
 *   </div>
 *
 * Liste des props :
 * id        : (Obligatoire) l'identifiant CSS de l'info-bulle.
 * children  : (Obligatoire) Les éléments JSX à afficher dans l'info-bulle.
 */
class Tooltip extends Component {
  /**
   * Constructeur, l'infobulle est masquée par défaut.
   *
   * @param props : les props du composant.
   */
  constructor(props) {
    super(props);
    this.state = {
      displayed: false,
      top: -165,
    };

    // On ajoute un listener pour déterminer quand la fenêtre est redimensionnée
    // (Orientation du téléphone portable).
    window.addEventListener('resize', this.handlePosition, true);
  }


  /**
   * Fonction appelée une fois le composant chargé.
   * On déclenche le calcul de la position de l'infobulle.
   */
  componentDidUpdate() {
    this.handlePosition();
  }

  /**
   * Fonction appelée à la destruction du composant.
   * On supprime le listener.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePosition, true);
  }

  /**
   * Fonction appelée lors du clic sur le bouton d'ouverture ou de fermeture.
   * On Affiche / masque l'info-bulle.
   * Si l'infobulle est masquée on redonne le focus au bouton d'ouverture.
   */
  openClose = () => {
    const newDisplayed = !this.state.displayed;
    this.setState((prevState) => ({
      displayed: !prevState.displayed,
    }));
    if (!newDisplayed) {
      this.openButton.focus();
    }
  };

  /**
   * Fonction qui permet de recalaculer la hauteur de l'infobulle pour déterminer sa position.
   * La fonction doit être appelée après le chargement du composant
   * et lorsque la fenêtre est redimensionnée.
   * La flèche en bas de l'infobulle mesure 10px de haut.
   */
  handlePosition = () => {
    const position = -10 - this.tooltip.offsetHeight;
    if (this.state.top !== position) {
      this.setState({
        top: position,
      });
    }
  };

  /**
   * Fonction qui permet de récupérer et rendre uniquement le contenu du bouton d'ouverture
   * de l'info-bulle passé dans les children.
   */
  renderElement = (type) => (
    React.Children.map(
      this.props.children,
      (item) => (item.type === type ? item.props.children : null),
    )
  );

  /**
   * Méthode de rendu du composant.
   */
  render() {
    const { id } = this.props;
    const { displayed } = this.state;
    const tooltipClassName = this.state.displayed ? 'tooltip-content' : 'tooltip-content js-hidden';

    return (
      <div>
        <button
          type="button"
          className="btn-tooltip"
          title={this.props.intl.formatMessage({ id: 'ctr.more.info.tooltip' })}
          onClick={this.openClose}
          aria-expanded={displayed}
          aria-controls={id}
          ref={(openButton) => {
            this.openButton = openButton;
          }}
        >
          {/* Le bouton d'ouverture de l'info-bulle */
            this.renderElement(TooltipButton)
          }
        </button>
        <div
          id={id}
          className={tooltipClassName}
          style={{ top: `${this.state.top}px` }}
          ref={(tooltip) => {
            this.tooltip = tooltip;
          }}
        >

          {/* Le bouton de fermeture de l'info-bulle */}
          <button
            type="button"
            className="btn-close-tooltip"
            aria-expanded={displayed}
            aria-controls={id}
            onClick={this.openClose}
          >
            <span className="snc-icon-close" aria-hidden="true" />
            <span className="visually-hidden"><FormattedMessage id="iv.close" /></span>
          </button>

          {/* Le contenu de l'info-nulle */
            this.renderElement(TooltipContent)
          }
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
};

export default Tooltip;
