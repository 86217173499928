import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import HeaderBlock from 'components/HeaderBlock/HeaderBlock';
import formatAmount from 'utils/formatAmount';

const formatDateTime = (intl, transaction) => intl.formatMessage({ id: 'ctr.resume.transaction.dateTime' }, { date: transaction.transactionDate, time: transaction.transactionTime });

const Resume = ({
  intl, transaction, lang, handleLang, handleStyle, onClickFinish, abandonTag,
}) => (
  <>
    <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
    <main role="main" id="main-content" className="main-content">
      <section className="container" style={{ marginTop: '70px' }}>
        <div className="main-div">
          <HeaderBlock intl={intl} step={4} />
          <h2 className="subtitle-step-name-num">
            {transaction.state === 'TRANSACTION'
              ? intl.formatMessage({ id: 'ctr.resume.subtitle' })
              : intl.formatMessage({ id: 'ctr.resume.subtitle.abandon' })}
            {transaction.phoneNumber}
          </h2>

          <div className="ctr-transaction-summary">
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.transaction.id' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">
                  {transaction.orderId}
                  /
                  {transaction.transactionId}
                </p>
              </div>
            </div>
            {transaction.transactionNumber
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.transaction.number' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="ctr-transaction-summary-value">{transaction.transactionNumber}</p>
                  </div>
                </div>
              ) : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.card_number' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{transaction.cardNumber}</p>
              </div>
            </div>
            {transaction.authorizationNumber
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.authorization_number' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="ctr-transaction-summary-value">{transaction.authorizationNumber}</p>
                  </div>
                </div>
              )
              : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.market_id' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{transaction.merchantId}</p>
              </div>
            </div>
            {transaction.certificate
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.transaction.certificate' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="ctr-transaction-summary-value">{transaction.certificate}</p>
                  </div>
                </div>
              )
              : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.date_time' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{formatDateTime(intl, transaction)}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.amount' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{formatAmount(transaction.totalAmount)}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.transaction.type' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{intl.formatMessage({ id: 'ctr.resume.transaction.type.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.brand' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{transaction.cardBrand}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.use' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{intl.formatMessage({ id: 'ctr.resume.use.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.url' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{intl.formatMessage({ id: 'ctr.resume.url.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.state' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                {transaction.state === 'TRANSACTION'
                  ? <p className="ctr-transaction-summary-value">{intl.formatMessage({ id: 'ctr.resume.state.value.paiement' })}</p>
                  : null}
                {transaction.state === 'ABANDON'
                  ? abandonTag
                  : null}
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="ctr-transaction-summary-label">{intl.formatMessage({ id: 'ctr.resume.type' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="ctr-transaction-summary-value">{transaction.transactionType}</p>
              </div>
            </div>
          </div>

          {/* The table in it's desktop version */}
          {transaction.penaltyInformationsList
          && (
          <div className="ctr-transaction-summary-table-large">
            <div role="table" aria-labelledby="ticket-table-title" className="ctr-table">
              <div role="rowgroup">
                <div role="row" className="ctr-table-row ctr-table-row-header">
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.name' })}</span>
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.num' })}</span>
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.amount' })}</span>
                  <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.comment' })}</span>
                </div>
              </div>

              <div role="rowgroup" className="ctr-table-content">
                {transaction.penaltyInformationsList
                  && transaction.penaltyInformationsList.map((t) => (
                    <div role="row" className="ctr-table-row" key={t.id}>
                      <span role="cell" className="ctr-table-cell">
                        {transaction.name}
                      </span>
                      <span role="cell" className="ctr-table-cell">
                        {t.identifiant1}
                      </span>
                      <span role="cell" className="ctr-table-cell">
                        {formatAmount(t.amount)}
                      </span>
                      <span role="cell" className="ctr-table-cell">
                        {t.typeIdentifiant1}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          )}
          {/* End of the table in it's desktop version */}

          {/* The table in it's mobile version */}
          <div className="ctr-transaction-summary-table-small">
            <ul className="ctr-ticket-list">
              <li className="ctr-ticket-item">
                <div role="table" aria-labelledby="ticket-table-title" className="ctr-table">
                  {transaction.penaltyInformationsList
                  && transaction.penaltyInformationsList.map((t) => (
                    <div role="rowgroup" key={t.id}>
                      <div role="row" className="ctr-table-row">
                        <span role="rowheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.name' })}</span>
                        <span role="cell" className="ctr-table-cell">{transaction.name}</span>
                      </div>
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.num' })}</span>
                        <span role="cell" className="ctr-table-cell">{t.identifiant1}</span>
                      </div>
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.amount' })}</span>
                        <span role="cell" className="ctr-table-cell">{formatAmount(t.amount)}</span>
                      </div>
                      <div role="row" className="ctr-table-row">
                        <span role="columnheader" className="ctr-table-cell ctr-table-cell-header">{intl.formatMessage({ id: 'ctr.resume.comment' })}</span>
                        <span role="cell" className="ctr-table-cell">{t.typeIdentifiant1}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </div>
          {/* End of the table in it's mobile version */}
          {/** Bouton PDF mise en commnentaire pour une possible réutilisation
            <button type="button" className="ctr-transaction-summary-link"
              target="_blank" title="récapitulatif - nouvelle fenêtre">
              {intl.formatMessage({ id: 'ctr.resume.download' })}
              <span aria-hidden="true"
                className="snc-icon-download ctr-transaction-summary-link-icon" />
            </button>
          */}
          <div className="btn-wrapper">
            <button type="button" className="btn btn-default btn-block btn-ctr-end" title="Terminer" onClick={onClickFinish}>
              {intl.formatMessage({ id: 'ctr.resume.finish' })}
            </button>
          </div>
        </div>
      </section>
    </main>
    <Footer lang={lang} handleLang={handleLang} intl={intl} />
  </>
);

// TODO mettre transaction et tickets en required et supprimer les defaultProps
Resume.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
  transaction: PropTypes.shape(),
  onClickFinish: PropTypes.func.isRequired,
  abandonTag: PropTypes.func.isRequired,
};

Resume.defaultProps = {
  transaction: {
    transactionId: 'XXXXXXXXX',
    transactionDate: 'XXXXXXXXX',
    transactionTime: 'XXXXXXXXX',
    transactionType: 'XXXXXXXXX',
    cardNumber: 'XXXXXXXXX',
    merchantId: 'XXXXXXXXX',
    totalAmount: 0,
    name: 'XXXXXXXXX',
    authorizationNumber: 'XXXXXXXXX',
    penaltyInformationsList: [
      {
        identifiant1: 'XXXXXXXXX',
        typeIdentifiant1: 'XXXXXXXXX',
        identifiant2: 'XXXXXXXXX',
        typeIdentifiant2: 'XXXXXXXXX',
        identifiant3: 'XXXXXXXXX',
        typeIdentifiant3: 'XXXXXXXXX',
        amount: 0,
      },
    ],
  },
};

export default injectIntl(Resume);
