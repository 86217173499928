import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';

const Step3 = ({
  ivrId, intl, onSubmit3, state,
}) => (
  <section className="container">
    <form onSubmit={onSubmit3}>
      <div>
        <p className="subtitle-step-name-num">
          <b>{intl.formatMessage({ id: 'ivr.id.text' })}</b>
          {ivrId}
        </p>
      </div>
      <div className="btn-wrapper">
        <button id="confirm_payment_btn" type="submit" className="btn btn-default btn-ctr-next" disabled={state.isSviLoading}>
          {intl.formatMessage({ id: 'step.button.confirm.payment' })}
        </button>
      </div>
    </form>
  </section>
);

Step3.propTypes = {
  ivrId: PropTypes.string.isRequired,
  onSubmit3: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
};
export default injectIntl(Step3);

