/**
 * Action creator generator used for departures and arrivals.
 */
import ws from 'services/ws';
import {
  FETCH_CONNEXION, RECEIVE_CONNEXION, RECEIVE_CONNEXION_FAILURE,
  SET_CODE_AGENT, SET_PASSWORD,
} from './ActionTypes';

export const requestConnexion = (
  name,
  numero,
  tokenCaptcha,
) => ({
  type: FETCH_CONNEXION,
  name,
  numero,
  tokenCaptcha,
});

export const receiveConnexion = (
  name,
  numero,
  res,
) => ({
  type: RECEIVE_CONNEXION,
  name,
  numero,
  res,
});

export const receiveFailureConnexion = (error) => ({
  type: RECEIVE_CONNEXION_FAILURE,
  error,
});

export const fetchConnexion = (
  name,
  numero,
  tokenCaptcha,
  dispatch,
  endpoint,
  timeout,
) => {
  requestConnexion(
    name,
    numero,
    tokenCaptcha,
  );
  ws(endpoint, timeout).connexionAgent(
    name,
    numero,
    tokenCaptcha,
  ).then(({ head }) => {
    sessionStorage.setItem('token.agent', head.authorization);
    window.location.replace('/');
  }, (error) => {
    dispatch(receiveFailureConnexion(error));
  });
};

export const setCodeAgent = (
  name = '',
) => ({
  type: SET_CODE_AGENT,
  name,
});

export const setPassword = (
  numero = '',
) => ({
  type: SET_PASSWORD,
  numero,
});
