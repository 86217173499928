import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import AccessibilityBar from 'components/Header/Accessibility/AccessibilityBar';
import logo from 'assets/images/logo-sncf.svg';


class Header extends Component {
  static deleteToken() {
    sessionStorage.removeItem('token.agent');
    document.location.reload(true);
  }

  render() {
    const tokenAgent = sessionStorage.getItem('token.agent');
    const isCalledFromLoginPage = this.props.calledFromLoginPage;
    return (
      <header
        role="banner"
        id="header"
        className="snc-header-site"
      >
        <AccessibilityBar
          lang={this.props.lang}
          handleLang={this.props.handleLang}
          handleStyle={this.props.handleStyle}
        />
        <div className="header-for-sticky snc-header-sticky">
          <div className="main-header">
            <div className="container grid">
              <div className="clearfix">
                <a href="/" className="navigation-logo-link">
                  <img src={logo} className="navigation-logo" alt="SNCF - Page d'accueil" />
                </a>
              </div>
              <div className="right authentification-block-button">
                {tokenAgent
                  ? (
                    <button
                      type="button"
                      className="button-header main-menu-navigation-search-btn"
                      id="main-menu-navigation-search-btn"
                      title=""
                      onClick={Header.deleteToken}
                    >
                      <span className="ctr-icon-circle-account-connected" />
                      <span className="ctr-text-loggin-account">
                        <FormattedMessage id="header.deconnexion" />
                      </span>
                    </button>
                  )
                  : !isCalledFromLoginPage && (
                    <a
                      href="/connexioninterne"
                      className="button-header main-menu-navigation-account-link main-menu-navigation-account-link-small"
                      title=""
                    >
                      <span className="ctr-icon-loggin-account" aria-hidden="true" />
                      <span className="ctr-text-loggin-account">
                        <FormattedMessage id="header.connexion" />
                      </span>
                    </a>
                  )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
  calledFromLoginPage: PropTypes.bool,
};

Header.defaultProps = {
  calledFromLoginPage: false,
};

export default Header;
