import {
  fetchAmount, receiveAmount, setPenalty, setName, setNumero, setStep,
  resetTicket, fetchCheckoutAmount, fetchAmountWithToken,
  receiveIvrIdForAgent,
} from 'actions/Step';
import { CLEAR_ERROR } from '../../../actions/ActionTypes';



/**
 * Clear error action creator.
 * @param {function} dispatch
 * @returns {function()} the bound clear error action creator.
 */
export const clearError = (dispatch) => () => (
  dispatch({ type: CLEAR_ERROR })
);

export const fetchTools = (dispatch) => (penalty, name, numero, endpoint, timeout) => {
  fetchAmount(
    penalty,
    name,
    numero,
    dispatch,
    endpoint,
    timeout,
  );
};

export const fetchToolsWithToken = (dispatch) => (token, endpoint, timeout) => {
  fetchAmountWithToken(
    token,
    dispatch,
    endpoint,
    timeout,
  );
};

export const fetchCheckoutTools = (dispatch) => (
  tickets,
  email,
  isPaymentPage,
  lang,
  endpoint,
  timeout,
) => {
  fetchCheckoutAmount(
    tickets,
    email,
    isPaymentPage,
    lang,
    dispatch,
    endpoint,
    timeout,
  );
};

export const setPenaltyTools = (dispatch) => (penalty) => {
  dispatch(setPenalty(penalty));
};

export const setNameTools = (dispatch) => (name) => {
  dispatch(setName(name));
};

export const setNumeroTools = (dispatch) => (numero) => {
  dispatch(setNumero(numero));
};

export const setStepTools = (dispatch) => (step) => {
  dispatch(setStep(step));
};

export const resetTicketTools = (dispatch) => (step) => {
  dispatch(resetTicket(step));
};

export const handleError = (dispatch) => (error, intl) => {
  let dispatcher;
  if (
    (error.status > 499 && error.status < 510)
    || error.status === undefined
  ) {
    dispatch(dispatcher(error, intl));
  } else if (
    error.status === 400
    || error.status === 404
    || error.status === 403) {
    dispatch(receiveAmount(error));
  }
};

export const receiveIvrId = (dispatch) => (
  tickets,
  email,
  isPaymentPage,
  lang,
  endpoint,
  timeout,
) => (
  receiveIvrIdForAgent(tickets, email, isPaymentPage, lang, dispatch, endpoint, timeout)
);
